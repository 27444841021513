<template>
  <div class="container mx-2">
    <!-- Breadcrumb  -->
    <va-card>
      <va-card-content>
        <div style="display: flex">
          <va-breadcrumbs color="primary">
            <va-breadcrumbs-item :label="$t('menu.users')" />
            <va-breadcrumbs-item :label="$t('menu.admins')" />
          </va-breadcrumbs>
        </div>
      </va-card-content>
    </va-card>
    <va-inner-loading :loading="loading" icon="restart_alt">
      <div class="mt-3">
        <div class="flex">
          <va-card stripe stripe-color="primary">
            <va-card-content>
              <h1
                v-if="admins.length == 0"
                class="row align--center justify--center"
                style="font-size: 32px; height: 35vh"
              >
                {{ $t("no_admins") }}
                <va-icon class="material-icons" size="32px"> schedule </va-icon>
              </h1>
              <va-list v-else>
                <va-button
                  color="#01377f"
                  class="mb-4"
                  @click="showAddModal = true"
                >
                  <span class="material-icons"> add </span>
                  {{ $t("add") }}
                </va-button>
                <va-list-item
                  v-for="(admin, index) in admins"
                  :key="admin.name"
                  to=""
                >
                  <strong>{{ index + offset + 1 }}.</strong>
                  <va-list-item-section avatar class="px-3">
                    <va-avatar>
                      <img v-if="admin.img" :src="admin.img" />
                      <div v-else>{{ admin.name.substring(0, 1) }}</div>
                    </va-avatar>
                  </va-list-item-section>

                  <va-list-item-section>
                    <va-list-item-label>
                      {{ admin.name }}
                    </va-list-item-label>

                    <va-list-item-label caption :lines="index + 1">
                      {{ admin.tel }}
                    </va-list-item-label>

                    <va-list-item-label caption :lines="index + 1">
                      {{ admin.email }}
                    </va-list-item-label>
                  </va-list-item-section>

                  <va-list-item-section icon>
                    <va-button flat @click="deleteAdmin(admin.id)">
                      <va-icon name="trash" color="danger" />
                    </va-button>
                  </va-list-item-section>
                </va-list-item>
              </va-list>
              <va-pagination
                v-if="pages > 1"
                color="#0a4695"
                style="direction: ltr"
                class="float-end"
                v-model="currentPage"
                :visible-pages="3"
                :pages="pages"
              />
            </va-card-content>
          </va-card>
        </div>
      </div>
    </va-inner-loading>
    <!-- Add editor modal -->
    <va-modal
      v-model="showAddModal"
      :title="$t('add') + ' ' + $t('menu.admins')"
      hide-default-actions
      fullscreen
      mobile-fullscreen
    >
      <add-admin-form />
    </va-modal>
  </div>
</template>

<script>
import { request, gql } from "graphql-request";
import AddAdminForm from "./components/AddAdminForm.vue";
export default {
  components: { AddAdminForm },
  data() {
    return {
      admins: [],
      showAddModal: false,
      adminsCount: null,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      loading: true,
    };
  },
  async created() {
    // Pagination Logic
    try {
      const ADMINS_COUNT = gql`
        query {
          UsersCount(where: { user_role_id: 2 }) {
            count
          }
        }
      `;
      const counterResponse = await request(
        this.$store.state.appUrl,
        ADMINS_COUNT
      );
      this.adminsCount = counterResponse.UsersCount.count;
      this.pages = Math.ceil(this.adminsCount / this.limit);
      this.getAdmins();
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getAdmins() {
      this.loading = true;
      this.offset = (this.currentPage - 1) * this.limit;
      const GET_ADMINS = gql`
        query ($limit: Int, $offset: Int) {
          admins: Users(
            where: { user_role_id: 2 }
            limit: $limit
            offset: $offset
          ) {
            id
            name
            email
            tel
          }
        }
      `;
      try {
        const response = await request(this.$store.state.appUrl, GET_ADMINS, {
          limit: this.limit,
          offset: this.offset,
        });
        this.admins = response.admins;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("errors.network"),
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if (result.value) {
              location.reload();
            }
          });
      }
    },
    async deleteAdmin(id) {
      const DELETE_ADMIN = gql`
        mutation ($id: Int!) {
          UsersDelete(id: $id)
        }
      `;
      this.$swal
        .fire({
          title: this.$t("sure?"),
          text: this.$t("delete_text"),
          icon: "question",
          showCancelButton: true,

          confirmButtonColor: "#e42222",
          confirmButtonText: this.$t("delete"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await request(
                this.$store.state.appUrl,
                DELETE_ADMIN,
                { id }
              );
              if (response.UsersDelete) {
                this.$swal.fire({
                  icon: "success",
                  title: this.$t("success"),
                  text: this.$t("delete_admin_success"),
                  confirmButtonColor: "#023b81",
                  confirmButtonText: this.$t("ok"),
                });
                this.getAdmins();
              } else {
                this.$swal.fire({
                  icon: "danger",
                  title: this.$t("failed"),
                  text: this.$t("delete_admin_fail"),
                  confirmButtonColor: "#023b81",
                  confirmButtonText: this.$t("ok"),
                });
              }
            } catch (error) {
              console.log(error);
            }
          }
        });
    },
  },
  provide() {
    return {
      getAdmins: this.getAdmins,
    };
  },
  watch: {
    async currentPage() {
      await this.getAdmins();
    },
  },
};
</script>

<style>
.va-list-item-section {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
